import '../css/page-content.css';

function PageContent() {
  return (
    <main className="page-content">
      <div className="page-content__container container">
        <div className="page-content__text-group">
          <h1>Please bear with us</h1>
          <p>The MND Association website is temporarily down due to improvement work on the website. We are working hard to get the website back up and running as soon as possible. We apologise for any inconvenience this may cause.</p>
          <p>If you need to get in touch with us, our MND Connect team offer information and support on all aspects of motor neurone disease and are available Monday to Friday, 9am – 5pm and 7pm - 10:30pm. You can reach them on 0808 802 6262 or via email <a href="mailto:mndconnect@mndassociation.org">mndconnect@mndassociation.org</a></p>
          <p>If you have queries directly related to fundraising for the MND Association, you can speak to our Fundraising team on 01604 611860 or via email <a href="mailto:fundraising@mndassociation.org">fundraising@mndassociation.org</a></p>
          <p>If you’d like to make a <a href="https://donate.mndassociation.org/?campaign=Main&amount=10&dontype=monthly">donation</a> to the MND Association, you can do so <a href="https://donate.mndassociation.org/?campaign=Main&amount=10&dontype=monthly">here</a>. </p>
          <p>If you would like more information about any of our <a href="https://fundraising.mndassociation.org/get-involved">fundraising events</a>, please click <a href="https://fundraising.mndassociation.org/get-involved">here</a>.</p>
          <p>You can keep in touch with our updates on our social media pages, search MND Association on <a href="https://www.facebook.com/mndassociation/">Facebook</a>, <a href="https://www.instagram.com/mndassoc/">Instagram</a> or <a href="https://twitter.com/mndassoc">Twitter</a>.</p>
          <p>Thank you for your understanding.</p>
        </div>
      </div>
    </main>
  );
}

export default PageContent;
