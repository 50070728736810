import '../css/page-footer.css';
import '../css/icons.css';
import { ReactComponent as PhoneIcon } from '../images/icon-phone.svg';
import { ReactComponent as LetterIcon } from '../images/icon-letter.svg';
import logoFr from '../images/logo-fr.svg';
import iconIg from '../images/icon-ig.svg';
import iconFb from '../images/icon-fb.svg';
import iconTw from '../images/icon-tw.svg';
import iconYt from '../images/icon-yt.svg';

function PageFooter() {
  const today = new Date();
  const year = today.getFullYear();
  
  return (
    <footer className="page-footer">
      <div className="page-footer__section page-footer__section--top">
        <div className="page-footer__section-container container">
          <div className="contact-item">
            <div className="contact-item__title">MND Connect helpline</div>
            <div className="contact-item__actions">
              <div className="icon-start"><PhoneIcon className="icon"/><a href="tel:08088026262">0808 802 6262</a></div>
              <div className="icon-start"><LetterIcon className="icon"/><a href="mailto:mndconnect@mndassociation.org">Email us</a></div>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-item__title">Fundraising enquiries</div>
            <div className="contact-item__actions">
              <div className="icon-start"><PhoneIcon className="icon"/><a href="tel:01604611860">01604 611860</a></div>
              <div className="icon-start"><LetterIcon className="icon"/><a href="mailto:fundraising@mndassociation.org">Email us</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-footer__section page-footer__section--center">
        <div className="page-footer__section-container container">
          <div className="page-footer__logo-group">
            <div className="page-footer__partner">
              <img src={logoFr} className="page-footer__partner-image" alt="Fundraising Regulator logo" />
            </div>
            <div className="page-footer__social">
              <div className="social-item social-item--ig"><a href="https://www.instagram.com/mndassoc/"><img src={iconIg} className="page-footer__social-icon" alt="Instagram logo" /></a></div>
              <div className="social-item social-item--fb"><a href="https://www.facebook.com/mndassociation/"><img src={iconFb} className="page-footer__social-icon" alt="Facebook logo" /></a></div>
              <div className="social-item social-item--tw"><a href="https://twitter.com/mndassoc"><img src={iconTw} className="page-footer__social-icon" alt="Twitter logo" /></a></div>
              <div className="social-item social-item--yt"><a href="https://www.youtube.com/mndassociationofficial"><img src={iconYt} className="page-footer__social-icon" alt="You Tube logo" /></a></div>
            </div>
          </div>
          <div className="page-footer__menu-group">
            <ul className="menu">
              <li><a href="https://shop.mndassociation.org/">Shop</a></li>
              <li><a href="https://www.mnda.raffleentry.org.uk">Raffle</a></li>
              <li><a href="https://jobs.mndassociation.org">Jobs</a></li>
            </ul>
            <ul className="menu">
              <li><a href="https://i.emlfiles4.com/cmpdoc/9/6/7/7/8/1/files/71755_privacypolicy.pdf">Privacy Policy</a></li>
              <li><a href="https://i.emlfiles4.com/cmpdoc/9/6/7/7/8/1/files/71767_termsandconditions.pdf">Terms and Conditions</a></li>
              <li><a href="https://i.emlfiles4.com/cmpdoc/9/6/7/7/8/1/files/71766_cookiepolicy.pdf">Cookie Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="page-footer__section page-footer__section--bottom">
        <div className="page-footer__section-container container">
          © {year} MND Association: Registered in England. Registered Charity no. 294354 VAT Registration no. 316 4340 28
        </div>
      </div>
    </footer>
  );
}

export default PageFooter;
