import './css/page.css';
import './css/typography.css';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import PageContent from './components/PageContent';

function App() {
  return (
    <div className="page">
      <PageHeader />
      <PageContent />
      <PageFooter />
    </div>
  );
}

export default App;
