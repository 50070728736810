import logo from '../images/logo.svg';
import { ReactComponent as PhoneIcon } from '../images/icon-phone.svg';
import '../css/page-header.css';
import '../css/button.css';
import '../css/icons.css';

function PageHeader() {
  return (
    <header className="page-header">
      <div className="page-header__section page-header__section--notice">
        <div className="page-header__section-container container">
          <div className="icon-start">
            <PhoneIcon className="icon"/>
            <span>MND Connect Helpline: <a href="tel:08088026262">0808 802 6262</a></span>
          </div>
          <div className="icon-start">
            <PhoneIcon className="icon"/>
            <span>Fundraising enquiries: <a href="tel:01604611860">01604 611860</a></span>
          </div>
        </div>
      </div>
      <div className="page-header__section page-header__section--main">
        <div className="page-header__section-container container-wide">
          <div className="page-header__logo">
            <img src={logo} className="page-header__logo-image" alt="MNDA logo" />
          </div>
          <div className="page-header__actions">
            <a className="button" href="https://donate.mndassociation.org/?campaign=Main&amount=10&dontype=monthly">Donate</a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default PageHeader;
